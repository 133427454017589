var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText, Typography, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
// components
import { UploadAvatar, Upload, UploadBox } from '../upload';
// translation
import { useLocales } from '../../locales';
import { fData } from '../../utils/formatNumber';
// ----------------------------------------------------------------------
export function RHFUploadAvatar(_a) {
    var name = _a.name, helperText = _a.helperText, other = __rest(_a, ["name", "helperText"]);
    var translate = useLocales().translate;
    var _b = useFormContext(), control = _b.control, setValue = _b.setValue;
    var handleDrop = useCallback(function (acceptedFiles) {
        var file = acceptedFiles[0];
        var newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });
        if (file) {
            setValue(name, newFile, { shouldValidate: true });
        }
    }, [setValue, name]);
    var handleRemoveFile = function () {
        setValue(name, '', { shouldValidate: true });
    };
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(_Fragment, { children: [_jsx(UploadAvatar, __assign({ ModelImage: "Logo *", accept: {
                            'image/*': [],
                        }, error: !!error, file: field.value, onDrop: handleDrop, onDelete: handleRemoveFile, helperText: _jsxs(Typography, __assign({ variant: "caption", sx: {
                                mt: 2,
                                mx: 'auto',
                                display: 'block',
                                textAlign: 'center',
                                color: 'text.secondary',
                            } }, { children: ["".concat(translate('Allowed *.jpeg, *.jpg, *.png, *.gif')), _jsx("br", {}), "".concat(translate("max size of")), fData(3145728), field.value && (_jsx(IconButton, __assign({ onClick: handleRemoveFile, color: "error", "aria-label": "delete" }, { children: _jsx(CancelIcon, { color: "error" }) })))] })) }, other)), !!error && (_jsx(FormHelperText, __assign({ error: true, sx: { px: 2, textAlign: 'center' } }, { children: "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) })))] }));
        } }));
}
// ----------------------------------------------------------------------
export function RHFUploadBox(_a) {
    var name = _a.name, other = __rest(_a, ["name"]);
    var control = useFormContext().control;
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(UploadBox, __assign({ files: field.value, error: !!error }, other)));
        } }));
}
// ----------------------------------------------------------------------
export function RHFUpload(_a) {
    var name = _a.name, multiple = _a.multiple, helperText = _a.helperText, files = _a.files, label = _a.label, other = __rest(_a, ["name", "multiple", "helperText", "files", "label"]);
    var _b = useFormContext(), control = _b.control, setValue = _b.setValue;
    var translate = useLocales().translate;
    var handleDrop = useCallback(function (acceptedFiles) {
        var file = acceptedFiles[0];
        var newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });
        if (file) {
            setValue(name, newFile, { shouldValidate: true });
        }
    }, [setValue, name]);
    var handleRemoveFile = function () {
        setValue(name, '', { shouldValidate: true });
    };
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return multiple ? (_jsx(Upload, __assign({ multiple: true, accept: { 'image/*': [], 'application/pdf': [] }, files: field.value, onDrop: handleDrop, onDelete: handleRemoveFile, error: !!error, helperText: (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error, sx: { px: 2 } }, { children: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) }))) }, other))) : (_jsx(Upload, __assign({ accept: { 'image/*': [], 'application/pdf': [] }, thumbnail: field.value, file: field.value, error: !!error, onDrop: handleDrop, onDelete: handleRemoveFile, helperText: (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error, sx: { px: 2 } }, { children: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) }))) }, other)));
        } }));
}
